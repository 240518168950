import * as React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import * as Styles from '../styles/pages/_contact.module.scss';
import * as CommonStyles from '../styles/global/_common.module.scss';
import { BreadCrumb } from '../components/breadcrumb';
import { BackGround } from '../components/background';
import {Link, useIntl} from "gatsby-plugin-react-intl"

const ContactPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [submitError, setSubmitError] = React.useState();
  const [submitComplete, setSubmitComplete] = React.useState();
  const [errorMailaddress, setErrorMailaddress] = React.useState(false);

  const apiKey = process.env.CONTACT_API_KEY;
  const apiUrl = process.env.CONTACT_API_URL;

  const onSubmit = async (data) => {
    setErrorMailaddress(false)
    const regex = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if (!regex.test(data.mail_address)) {
      setErrorMailaddress(true)
      return false
    }
    await axios
      .post(apiUrl, data, { headers: { 'Content-Type': 'application/json', 'X-MICROCMS-API-KEY': apiKey } })
      .then(() => {
        setSubmitComplete(true);
        reset();
        setTimeout(() => setSubmitComplete(false), 3000);
      })
      .catch((error) => {
        if (error.response) {
          setSubmitError(true);
          setTimeout(() => setSubmitError(false), 3000);
        }
      });
  };

  return (
    <Layout location={location}>
      <div className={classNames(Styles.contact__submitAlert, Styles.submitComplete, submitComplete && Styles.active)}>
        <p>{t('contact.sendComplete')}</p>
      </div>

      <div className={classNames(Styles.contact__submitAlert, Styles.submitError, submitError && Styles.active)}>
        <p>{t('contact.sendFailed')}</p>
      </div>
      <Seo title={t('contact.seo.title')} description={t('contact.seo.description')} />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
        ]}
        currentPage={t('footer.inquiry')}
      />
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <h1>{t('footer.inquiry')}</h1>
            <p className={Styles.contact__centertext}>{t('contact.message1')}</p>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <table className={Styles.contact__table}>
                  <tr>
                    <th>
                      <label className={Styles.contact__label} htmlFor="from">
                        {t('contact.name')}<span>{t('contact.indispensable')}</span>
                      </label>
                    </th>
                    <td className={Styles.contact__input}>
                      <input id="from" name="from" {...register('from', { required: true })} />
                      {errors.from && <p className={Styles.formError}>{t('contact.message2')}</p>}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className={Styles.contact__label} htmlFor="company_name">
                        {t('contact.companyName')}
                      </label>
                    </th>
                    <td className={Styles.contact__input}>
                      <input id="company_name" {...register('company_name', { required: false })} />
                      {/*{errors.company_name && <p className={Styles.formError}>{t('contact.message3')}</p>}*/}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className={Styles.contact__label} htmlFor="mail_address">
                        {t('contact.mailAddress')}<span>{t('contact.indispensable')}</span>
                      </label>
                    </th>
                    <td className={Styles.contact__input}>
                      <input
                        id="mail_address"
                        type="mail_address"
                        {...register('mail_address', {
                          required: true,
                        })}
                      />
                      {(errors.mail_address || errorMailaddress) && <p className={Styles.formError}>{t('contact.message3')}</p>}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className={Styles.contact__label} htmlFor="organization-department">
                        {t('contact.inquiryType')}<span>{t('contact.indispensable')}</span>
                      </label>
                    </th>
                    <td className={Styles.contact__radio}>
                      <label>
                        <input {...register('subject', { required: true })} type="radio" value={t('contact.type1')} />
                        {t('contact.type1')}
                      </label>
                      <label>
                        <input {...register('subject', { required: true })} type="radio" value={t('contact.type2')} />
                        {t('contact.type2')}
                      </label>
                      <label>
                        <input {...register('subject', { required: true })} type="radio" value={t('contact.type3')} />
                        {t('contact.type3')}
                      </label>
                      {/*<label>*/}
                      {/*  <input {...register('subject', { required: true })} type="radio" value={t('contact.type4')} />*/}
                      {/*  {t('contact.type4')}*/}
                      {/*</label>*/}
                      <label>
                        <input {...register('subject', { required: true })} type="radio" value={t('contact.type5')} />
                        {t('contact.type5')}
                      </label>
                      <label>
                        <input {...register('subject', { required: true })} type="radio" value={t('contact.type6')} />
                        {t('contact.type6')}
                      </label>
                      {errors.subject && <p className={Styles.formError}>{t('contact.typeSelect')}</p>}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label className={Styles.contact__label} htmlFor="content">
                        {t('contact.contents')}<span>{t('contact.indispensable')}</span>
                      </label>
                    </th>
                    <td className={Styles.contact__input_text}>
                      <textarea
                        id="body"
                        {...register('body', {
                          required: true,
                          rows: 8,
                          cols: 65,
                        })}
                      />
                      {errors.body && <p className={Styles.formError}>{t('contact.message4')}</p>}
                    </td>
                  </tr>
                </table>
                <div className={Styles.contact__btn_wrap}>
                  <button className={Styles.contact__btn} type="submit">
                    {t('contact.send')}
                  </button>
                </div>
              </form>
            </div>

            {/*<div className={Styles.contact__contents}>*/}
            {/*  <h3>{t('contact.inquiryTel')}</h3>*/}
            {/*  <p>*/}
            {/*    {t('contact.tel')}*/}
            {/*    <br />*/}
            {/*    {t('contact.receptionHours')}*/}
            {/*  </p>*/}
            {/*</div>*/}
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default ContactPage;
